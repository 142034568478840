<template>
  <div class="dizme_tm_section">
    <div class="dizme_tm_copyright">
      <div class="container">
        <div class="inner">
          <div class="left wow fadeInLeft" data-wow-duration="1s">
            <p>
              Developed by
              <a
                href="https://www.upwork.com/freelancers/~019b2ef6d52ddd90a0?mp_source=share"
                target="_blank"
                >Tegar Pratama</a
              >
              &copy; {{ new Date().getFullYear() }}
            </p>
          </div>
          <div class="right wow fadeInRight" data-wow-duration="1s">
            <ul>
              <li>
                <a href="https://wa.me/6285761652392" target="_blank"
                  >Whatsapp</a
                >
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/in/tegar-pratama-21a885213/"
                  target="_blank"
                  >Linkedin</a
                >
              </li>
              <li>
                <a
                  href="https://www.upwork.com/freelancers/~019b2ef6d52ddd90a0?mp_source=share"
                  target="_blank"
                  >Upwork</a
                >
              </li>
              <li><a href="mailto:mail.tegarp00@gmail.com">Email Me</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CopyrightComponent",
  components: {},
};
</script>
